import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const background = `${process.env.PUBLIC_URL}/img/landing/right_bg.png`;

const home = `${process.env.PUBLIC_URL}/img/dash/home_dash.png`;
const campanha = `${process.env.PUBLIC_URL}/img/dash/campanha_dash.png`;
const relatorio = `${process.env.PUBLIC_URL}/img/dash/relatorio_dash.png`;

const Technologies = () => (
  <section className="landing__section">
    <img
      className="landing__section-background landing__section-background--technologies"
      src={background}
      alt=""
    />
    <Container>
      <Row className="landing__code landing__code--first landing__code--left">
        <Col md={6} sm={12} xs={12}>
          <div className="landing__code-text">
            <div className="landing__code-wrap">
              <h3 className="landing__section-title">Programa de Fidelidade</h3>
              <p className="landing__section-description">
                O Winn permite a qualquer empresa criar um programa de cashback
                para suas marcas de forma individualizada. No dashboard a
                empresa adicionará as marcas e seus responsáveis por cada marca.
                Fará a inclusão dos produtos, mídias, pesquisas e campanhas.
              </p>
            </div>
          </div>
        </Col>
        <Col md={6} sm={12} xs={12}>
          <div className="landing__code-img landing__code-img--no-shadow">
            <div className="landing__code-wrap">
              <img
                className="landing__img landing__img--over2"
                src={home}
                alt=""
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row className="landing__code landing__code--right">
        <Col md={6} sm={12} xs={12}>
          <div className="landing__code-text">
            <div className="landing__code-wrap">
              <h3 className="landing__section-title">
                Fidelização com Recompensa
              </h3>
              <p className="landing__section-description">
                O Winn permite a qualquer empresa criar um programa de
                fidelidade de cashback para suas marcas de forma
                individualizada. Um ganha ganha para todos. Cada centavo
                investido pela empresa gera venda. Inversão igual a conversão.
              </p>
            </div>
          </div>
        </Col>
        <Col md={6} sm={12} xs={12}>
          <div className="landing__code-img landing__code-img--no-shadow">
            <div className="landing__code-wrap">
              <img
                className="landing__img landing__img--over2-right"
                src={campanha}
                alt=""
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row className="landing__code landing__code--first landing__code--left">
        <Col md={6} sm={12} xs={12}>
          <div className="landing__code-text">
            <div className="landing__code-wrap">
              <h3 className="landing__section-title">Relatórios</h3>
              <p className="landing__section-description">
                Toda ação gera um relatório para acompanhar suas campanhas e
                seus resultados. Nunca foi tão facil acompanhar os dados para a
                Marca junto aos estabelecimentos.
              </p>
            </div>
          </div>
        </Col>
        <Col md={6} sm={12} xs={12}>
          <div className="landing__code-img landing__code-img--no-shadow">
            <div className="landing__code-wrap">
              <img
                className="landing__img landing__img--over2"
                src={relatorio}
                alt=""
              />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
);

export default Technologies;
