import React from 'react';
import { Col, Row, Container } from 'reactstrap';

const feat00 = `${process.env.PUBLIC_URL}/img/landing/features/00_new.png`;
const feat01 = `${process.env.PUBLIC_URL}/img/landing/features/01_new.png`;
const feat02 = `${process.env.PUBLIC_URL}/img/landing/features/02_new.png`;
const feat03 = `${process.env.PUBLIC_URL}/img/landing/features/03_new.png`;
const feat04 = `${process.env.PUBLIC_URL}/img/landing/features/04_new.png`;
const feat05 = `${process.env.PUBLIC_URL}/img/landing/features/05_new.png`;

const Features = () => (
  <section className="landing__section">
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="landing__section-title">Campanhas</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="landing__features-wrap">
            <div className="landing__feature">
              <div className="landing__feature-img-wrap">
                <img
                  src={feat00}
                  alt=""
                />
              </div>
              <p className="landing__feature-title">Sorteio</p>
              <p className="landing__feature-caption">
                A cada cupom validado você concorre a 1 prêmio de R$ 1.000,00.
              </p>
            </div>
            <div className="landing__feature">
              <div className="landing__feature-img-wrap">
                <img src={feat01} alt="" />
              </div>
              <p className="landing__feature-title">Ofertas</p>
              <p className="landing__feature-caption">
                Com o Winn você recebe notificações de campanhas personalizadas.
              </p>
            </div>
            <div className="landing__feature">
              <div className="landing__feature-img-wrap">
                <img src={feat02} alt="" />
              </div>
              <p className="landing__feature-title">Perguntas</p>
              <p className="landing__feature-caption">
                Para você poder receber mais campanhas, precisamos saber um
                pouco mais sobre você. Responda as perguntas e ganhe dinheiro na
                hora.
              </p>
            </div>
            <div className="landing__feature">
              <div className="landing__feature-img-wrap">
                <img src={feat03} alt="" />
              </div>
              <p className="landing__feature-title">Doação</p>
              <p className="landing__feature-caption">
                Com o Winn você pode beneficiar também outras pessoas. No
                aplicativo você encontra inúmeras instituições para poder doar.
              </p>
            </div>
            <div className="landing__feature">
              <div className="landing__feature-img-wrap">
                <img src={feat04} alt="" />
              </div>
              <p className="landing__feature-title">WinnCash</p>
              <p className="landing__feature-caption">
                Você é dono do seu dinheiro e tudo o que você ganhar de
                recompensa poderá ser usado como você quiser.
              </p>
            </div>
            <div className="landing__feature">
              <div className="landing__feature-img-wrap">
                <img src={feat05} alt="" />
              </div>
              <p className="landing__feature-title">Leitura</p>
              <p className="landing__feature-caption">
                Ao finalizar uma compra, escaneie o cupom no próprio app.
                Aguarde a validação para ganhar dinheiro na sua conta digital.
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
);

export default Features;
