import React, { useState } from 'react';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { Row, Col, ButtonToolbar, Button } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { store } from 'react-notifications-component';

const baseURL =
  'https://be6qj1e0ef.execute-api.sa-east-1.amazonaws.com/production/sendEmailToContact';

const Form = () => {
  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors }
  } = useForm();

  const notificationSuccess = () => {
    store.addNotification({
      title: 'Sucesso!',
      message:
        'Sua mensagem foi enviada e logo entraremos em contato com você.',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    });
  };

  const notificationError = () => {
    store.addNotification({
      title: 'Erro!',
      message:
        'Um erro inesperado ocorreu, aguarde alguns minutos ou tente novamente mais tarde.',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    });
  };

  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    setLoading(true);
    axios
      .post(baseURL, data)
      .then(() => {
        notificationSuccess();
        reset();
        setLoading(false);
      })
      .catch(() => {
        notificationError();
        setLoading(false);
      });
  };

  return (
    <Row>
      <Col md={12} lg={12}>
        <Row>
          <Col md={3} lg={3} />
          <Col md={6} lg={6}>
            <h4 className="landing__additional-title landing__applications-description">
              Ficou com dúvidas, entre em contato com o nosso time que logo
              iremos iremos responder você.
            </h4>
            {loading ? (
              <div className="todo__panel-refresh">
                <LoadingIcon />
              </div>
            ) : (
              <form
                className="material-form"
                onSubmit={handleSubmit(onSubmit)}
                style={{
                  marginTop: '100px',
                  marginBottom: '100px',
                  padding: '0 36px'
                }}
              >
                <div>
                  <span className="material-form__label">Nome</span>
                  <TextField
                    name="name"
                    className="material-form__field"
                    {...register('name', {
                      required: 'Este campo é obrigatório.',
                      maxLength: {
                        value: 120,
                        message: 'Você excedeu o tamanho máximo do campo.'
                      }
                    })}
                  />
                  {errors.name && (
                    <span className="error">{errors.name.message}</span>
                  )}
                </div>
                <div>
                  <span className="material-form__label">Email</span>
                  <TextField
                    name="email"
                    className="material-form__field"
                    {...register('email', {
                      required: 'Este campo é obrigatório.',
                      pattern: {
                        value: /^\S+@\S+$/i,
                        message: 'Insira um email válido'
                      }
                    })}
                  />
                  {errors.email && (
                    <span className="error">{errors.email.message}</span>
                  )}
                </div>
                <div>
                  <span className="material-form__label">Categoria</span>
                  <Controller
                    name="type"
                    control={control}
                    defaultValue={false}
                    rules={{ required: 'Este campo é obrigatório.' }}
                    render={({ field }) => (
                      <TextField
                        name="type"
                        className="material-form__field"
                        {...field}
                        select
                      >
                        <MenuItem
                          className="material-form__option"
                          value="Dúvida"
                        >
                          Dúvida
                        </MenuItem>
                        <MenuItem
                          className="material-form__option"
                          value="Reclamação"
                        >
                          Reclamação
                        </MenuItem>
                        <MenuItem
                          className="material-form__option"
                          value="Sugestação"
                        >
                          Sugestação
                        </MenuItem>
                        <MenuItem
                          className="material-form__option"
                          value="Agredecimento"
                        >
                          Agradecimento
                        </MenuItem>
                        <MenuItem
                          className="material-form__option"
                          value="Parceria"
                        >
                          Parceria
                        </MenuItem>
                      </TextField>
                    )}
                  />
                  {errors.type && (
                    <span className="error">{errors.type.message}</span>
                  )}
                </div>
                <div>
                  <span className="material-form__label">Mensagem</span>
                  <TextField
                    name="message"
                    className="material-form__field"
                    multiline
                    minRows={4}
                    maxRows={4}
                    {...register('message', {
                      required: 'Este campo é obrigatório.',
                      maxLength: {
                        value: 250,
                        message: 'Você excedeu o tamanho máximo do campo.'
                      }
                    })}
                  />
                  {errors.message && (
                    <span className="error">{errors.message.message}</span>
                  )}
                </div>
                <ButtonToolbar
                  className="form__button-toolbar"
                  style={{ justifyContent: 'flex-end' }}
                >
                  <Button color="dark-blue" type="submit">
                    Enviar
                  </Button>
                </ButtonToolbar>
              </form>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Form;
