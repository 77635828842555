import React from 'react';
import PropTypes from 'prop-types';

const MainWrapper = ({ children }) => {
  const direction = 'ltr';

  return (
    <div className="light ltr-support" dir={direction}>
      <div className="wrapper">{children}</div>
    </div>
  );
};

MainWrapper.propTypes = {
  children: PropTypes.element.isRequired
};

export default MainWrapper;
