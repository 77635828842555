import React from 'react';
import { Col, Row, Container } from 'reactstrap';

const video =
  'https://winn-files.s3.sa-east-1.amazonaws.com/site/video-home.mp4';
const imgVideo =
  'https://winn-files.s3.sa-east-1.amazonaws.com/site/banner-home.png';

const Header = () => (
  <div>
    <div className="landing__header_video">
      <Container>
        <Row>
          <Col md={12}>
            <video
              src={video}
              controls
              className="landing__video"
              poster={imgVideo}
            />
          </Col>
        </Row>
      </Container>
    </div>
  </div>
);

export default Header;
