import React, { useState } from 'react';
import { Col, Row, Container } from 'reactstrap';
import { Link as Navigator } from 'react-router-dom';
import PreviousWinners from './PreviousWinners';

const Information = () => {
  const [visible, setVisible] = useState(false);

  const onShow = () => {
    setVisible(true);
  };

  return (
    <Container>
      <Row style={{ margin: '150px 0px' }}>
        <Col md={12} className="d-flex justify-content-center">
          <Navigator to="regulamento">
            <div style={{ padding: '0px 20px' }}>
              <button
                className="landing__btn landing__btn--nav landing__btn--info"
                // eslint-disable-next-line max-len
                type="button"
              >
                Regulamento
              </button>
            </div>
          </Navigator>
          <div style={{ padding: '0px 20px' }}>
            <button
              className="landing__btn landing__btn--nav landing__btn--info"
              // eslint-disable-next-line max-len
              onClick={onShow}
              type="button"
            >
              Sorteio anteriores
            </button>
          </div>
        </Col>
      </Row>
      {visible ? <PreviousWinners /> : ''}
    </Container>
  );
};

export default Information;
