import React from 'react';
import { Col, Row, Container } from 'reactstrap';

const Features = () => (
  <section className="landing__section">
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="landing__section-title">Vantagens</h3>
          <p className="landing__section-subtitle"> </p>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="landing__features-wrap">
            <div className="landing__feature">
              <p className="landing__feature-title">Baixo Custo Operacional</p>
              <p className="landing__feature-caption">
                Você cria sua campanha. Não precisa investir com materiais de
                comunicação no PDV. Resultados efetivos e análise automática do
                investimento
              </p>
            </div>
            <div className="landing__feature">
              <p className="landing__feature-title">Criptografia</p>
              <p className="landing__feature-caption">
                Toda a segurança nas informações da marca e usuários. Queremos
                proteger tanto o emissor da informação quanto o receptor.
              </p>
            </div>
            <div className="landing__feature">
              <p className="landing__feature-title">Transações Certificadas</p>
              <p className="landing__feature-caption">
                As transações certificadas que permitem a identificação segura
                do autor de uma mensagem ou transação financeira das marcas para
                os consumidores.
              </p>
            </div>
            <div className="landing__feature">
              <p className="landing__feature-title">Feedback</p>
              <p className="landing__feature-caption">
                A empresa receberá automaticamente o ROI ou CPM dependendo da
                campanha realizada.
              </p>
            </div>
            <div className="landing__feature">
              <p className="landing__feature-title">Validação Segura</p>
              <p className="landing__feature-caption">
                Asseguramos todas as validações do cupom fiscal junto a SEFAZ
                bem como o rastreamento de possíveis fraudes por parte dos
                estabelecimentos ou consumidores.
              </p>
            </div>
            <div className="landing__feature">
              <p className="landing__feature-title">Hipersegmentação</p>
              <p className="landing__feature-caption">
                Um filtro com todas as informações necessárias para criação de
                campanhas seguras, direcionadas e efetivas
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
);

export default Features;
