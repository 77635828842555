import React from 'react';
import { Element } from 'react-scroll';
import Header from './components/Header';
import HeaderTop from '../../shared/Header';
import Features from './components/Features';
import Footer from '../../shared/Footer';
import FeatureRequest from './components/FeatureRequest';
import Code from './components/Code';

const Home = () => {
  return (
    <div className="landing">
      <HeaderTop
        title="Com Winn é dinheiro na mão"
        subTitle="Receba recompensas diariamente"
      />
      <Header />
      <Element name="about" />
      <Code />
      <Element name="features" />
      <Features />
      <Element name="feature_request" />
      <FeatureRequest />
      <Footer />
    </div>
  );
};

export default Home;
