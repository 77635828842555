import React from 'react';
import { Col, Row, Container } from 'reactstrap';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

const pdf = `${process.env.PUBLIC_URL}/pdf/Regulamento.pdf`;

const PdfViewer = () => {
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
      <Container>
        <Row>
          <Col md={12}>
            <Viewer fileUrl={pdf} defaultScale={SpecialZoomLevel.PageWidth} />
          </Col>
        </Row>
      </Container>
    </Worker>
  );
};

export default PdfViewer;
