import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const codeExample = `${process.env.PUBLIC_URL}/img/landing/code_example.png`;
const responsive = `${process.env.PUBLIC_URL}/img/landing/admin_book.png`;
const background = `${process.env.PUBLIC_URL}/img/landing/right_bg.png`;

const Technologies = () => (
  <section className="landing__section">
    <img
      className="landing__section-background landing__section-background--technologies"
      src={background}
      alt=""
    />
    <Container>
      <Row className="landing__code landing__code--first landing__code--left">
        <Col md={6} sm={12} xs={12}>
          <div className="landing__code-text">
            <div className="landing__code-wrap">
              <h3 className="landing__section-title">
                Diversas campanhas no seu celular, muitas oportunidades pra
                ganhar.
              </h3>
              <p className="landing__section-description">
                Com o Winn é muito fácil ganhar: valide o cupom fiscal de
                compras feitas em qualquer estabelecimento comercial para ganhar
                dinheiro diariamente e participar de <b>Sorteios</b> de créditos
                em sua conta digital todos os meses.
              </p>
            </div>
          </div>
        </Col>
        <Col md={6} sm={12} xs={12}>
          <div className="landing__code-img landing__code-img--no-shadow">
            <div className="landing__code-wrap">
              <img
                className="landing__img landing__img--over"
                src={responsive}
                alt=""
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row className="landing__code landing__code--right">
        <Col md={6} sm={12} xs={12}>
          <div className="landing__code-text">
            <div className="landing__code-wrap">
              <h3 className="landing__section-title">
                Canal Multipromocional quanto mais você utilizar, maior a chance
                de ganhar
              </h3>
              <p className="landing__section-description">
                As campanhas estarão presentes no seu dia a dia de diferentes
                formas: cashback, doação, sorteio, oferta, pergunta e
                recompensa. Muitas oportunidades para você participar de cada
                uma delas e ganhar todos os dias.
              </p>
            </div>
          </div>
        </Col>
        <Col md={6} sm={12} xs={12}>
          <div className="landing__code-img landing__code-img--no-shadow">
            <div className="landing__code-wrap">
              <img
                className="landing__img landing__img--over-right"
                src={codeExample}
                alt=""
              />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
);

export default Technologies;
