import React from 'react';
import { Col, Row, Container } from 'reactstrap';
import FacebookIcon from 'mdi-react/FacebookIcon';
import TwitterIcon from 'mdi-react/TwitterIcon';
import InstagramIcon from 'mdi-react/InstagramIcon';
import LinkedinIcon from 'mdi-react/LinkedinIcon';
import { Link } from 'react-router-dom';

const background = `${process.env.PUBLIC_URL}/img/landing/footer_bg.png`;

const Footer = () => (
  <footer className="landing__footer">
    <img className="landing__footer-background" src={background} alt="" />
    <Container>
      <Row>
        <Col md={12}>
          <div className="account__social">
            <Link
              className="account__social-btn account__social-btn--facebook"
              to={{ pathname: 'https://www.facebook.com/winncomvc' }}
              target="_blank"
            >
              <FacebookIcon />
            </Link>
            <Link
              className="account__social-btn account__social-btn--google"
              to={{ pathname: 'https://twitter.com/winncomvc' }}
              target="_blank"
            >
              <TwitterIcon />
            </Link>
            <Link
              className="account__social-btn account__social-btn--instagram"
              to={{ pathname: 'https://www.instagram.com/winncomvc' }}
              target="_blank"
            >
              <InstagramIcon />
            </Link>
            <Link
              className="account__social-btn account__social-btn--linkedin"
              to={{ pathname: 'https://www.linkedin.com/winncomvc' }}
              target="_blank"
            >
              <LinkedinIcon />
            </Link>
          </div>
        </Col>
        <Col md={12}>
          <p className="landing__footer-text">
            Direitos reservados <b> Winn </b> copyright 2021
          </p>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
