import React from 'react';
import Header from './components/Header';
import HeaderTop from '../../shared/Header';
import Features from './components/Features';
import Footer from '../../shared/Footer';
import FeatureRequest from './components/FeatureRequest';
import Code from './components/Code';

const Parceiro = () => {
  return (
    <div className="landing">
      <HeaderTop
        title="Fidelize o seu consumidor e "
        subTitle="aumente suas vendas"
      />
      <Header />
      <Code />
      <Features />
      <FeatureRequest />
      <Footer />
    </div>
  );
};

export default Parceiro;
