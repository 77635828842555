import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-scroll';
import { Link as Navigator } from 'react-router-dom';
import { Col, Row, Container } from 'reactstrap';

const logo = `${process.env.PUBLIC_URL}/img/logo.png`;
const background = `${process.env.PUBLIC_URL}/img/landing/header_bg.png`;

const Header = ({ title, subTitle }) => {
  return (
    <div>
      <div className="landing__menu">
        <Container>
          <Row>
            <Col>
              <div className="landing__menu-wrap">
                <div className="landing__menu-logo">
                  <Navigator to="/">
                    <img src={logo} alt="" className="logo-header" />
                  </Navigator>
                </div>
                <nav className="landing__menu-nav">
                  <div className="landing__menu-nav--button">
                    <Link
                      activeClass="active"
                      to="about"
                      spy
                      smooth
                      duration={500}
                    >
                      <button type="button">Como funciona</button>
                    </Link>
                    <Navigator to="sorteio">
                      <button type="button">Sorteio</button>
                    </Navigator>
                    <Navigator to="faq">
                      <button type="button">Dúvidas</button>
                    </Navigator>
                    <Navigator to="fale">
                      <button type="button">Fale conosco</button>
                    </Navigator>
                  </div>
                  <Navigator className="landing__btn landing__btn--nav" to="parceiro">
                    <button type="button">Seja um parceiro</button>
                  </Navigator>
                  {/* <div>
                    <a
                      className="landing__btn landing__btn--nav landing__btn--hire-us"
                      target="_blank"
                      rel="noopener noreferrer"
                      // eslint-disable-next-line max-len
                      href="https://dash.winn.com.vc"
                    >
                      Seja um parceiro
                    </a>
                  </div> */}
                </nav>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        className="landing__header"
        style={{ backgroundImage: `url(${background})` }}
      >
        <Container>
          <Row>
            <Col md={12}>
              <h2 className="landing__header-title">
                {title} <br />
                {subTitle}
              </h2>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
};

Header.defaultProps = {
  subTitle: '',
};

export default Header;
