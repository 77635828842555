import React from 'react';
import ReactNotification from 'react-notifications-component';
import Header from '../../shared/Header';
import Footer from '../../shared/Footer';
import Form from './components/Form';

const ContactUs = () => {
  return (
    <div className="landing">
      <ReactNotification />
      <Header title="Fale conosco" />
      <Form />
      <Footer />
    </div>
  );
};

export default ContactUs;
