import React from 'react';
import {
  Card, CardBody, Col, Container, Row,
} from 'reactstrap';
import Collapse from '../../../shared/components/Collapse';

const FAQs = () => (
  <Container className="landing__faq">
    <Row>
      <Col md={12}>
        <h3 className="page-title">FICOU COM DÚVIDAS?</h3>
        <h3 className="page-subhead subhead">
          Estamos aqui para ajudar.
        </h3>
      </Col>
    </Row>
    <Row>
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <Collapse
              className="with-shadow"
              title="Baixei o aplicativo Winn e realizei meu cadastro. Como faço para ganhar cashback? "
            >
              <p>
                O app Winn é simples e fácil de utilizar! Após realizar seu
                cadastro, basta inserir os cupons fiscais de suas compras no app
                e você já estará participando, tendo a possibilidade de diversas
                formas de ganho. Você pode ganhar cashback quando no seu cupom
                fiscal possuir produtos das marcas participantes no app. Para
                cada cupom fiscal inserido no app e aceito (validado) você
                recebe um número da sorte para participar dos sorteios mensais
                de 10 (dez) prêmios de R$ 1.000,00 Através do seu perfil e
                habito de consumo, as marcas poderão te enviar perguntas e
                ofertas exclusivas para turbinar o seu ganho de cashback. Além
                do mais, se você atingir 100 cupons fiscais validados (aceitos)
                no app Winn, você ganha um super bônus de R$ 10,00.
              </p>
            </Collapse>
            <Collapse
              className="with-shadow"
              title="Posso enviar qualquer cupom fiscal para o aplicativo?"
            >
              <p>
                Não. O app Winn proporciona a possibilidade do ganho de cashback
                e possibilita a conexão direta entre marcas e consumidores
                através do cupom fiscal de suas compras de consumo próprio.
                Sendo assim, as marcas participantes do app te retorna um valor
                de cashback por ser fiel a elas referente ao seu habito de
                consumo próprio. Sendo assim deve inserir no app apenas cupons
                fiscais de compras de seu consumo próprio.
              </p>
            </Collapse>
            <Collapse
              className="with-shadow"
              title="Por que alguns cupons fiscais são aceitos (validados) e outros são negados?"
            >
              <p>
                Para evitar o mal uso do aplicativo e garantir um bom
                funcionamento da plataforma, com relação aos cupons fiscais o
                app Winn possui algumas regras de usabilidade e aceitação dos
                cupons fiscais. Quando o cupom fiscal inserido no app Winn
                estiver dentro das regras de usabilidade ele é aceito
                (validado), caso contrário o mesmo será invalidado/negado. Para
                ter a melhor experiência com o app Winn, não deixe de ler os
                termos de uso disponível no menu do app Winn, em específico o
                item 3 Uso da plataforma Winn com você.
              </p>
            </Collapse>
            <Collapse
              className="with-shadow"
              title="Enviei um cupom fiscal para o aplicativo e não ganhei cashback, Por que?"
            >
              <p>
                Você não ganha cashback por cupom fiscal enviado. Se você
                inseriu um cupom fiscal e não ganhou cashback é porque em seu
                cupom fiscal não foi identificado nenhum produto (s) das marcas
                participantes no app Winn, mas não desanime! Você ainda tem
                possibilidades de ganho com o seu cupom fiscal, sendo elas:
                Quando você inserir um cupom fiscal de suas compras no app Winn
                e o mesmo for validado (aceito) você recebe um número da sorte
                para participar dos sorteios mensais de 10 (dez) prêmios de R$
                1.000,00. Ao atingir 100 cupons fiscais validados (aceitos) no
                app Winn, você ganha um superbônus de R$ 10,00
              </p>
            </Collapse>
            <Collapse
              className="with-shadow"
              title="Como funciona as campanhas (ofertas e perguntas) das marcas cadastradas no Winn?"
            >
              <p>
                Quando mais cupons fiscais você inserir no app Winn, além de
                aumentar suas chances de ganhar os sorteios mensais e somar para
                atingir a meta de 100 cupons fiscais validados (aceitos) e
                conquistar o bônus de R$ 10,00 com as informações do seu perfil
                e hábitos de consumo fornecidos por você através do seu cupom
                fiscal, as marcas podem criar campanhas promocionais exclusivas
                para você no próprio app Winn. Quanto mais cupons fiscais você
                inserir e validar no aplicativo, mais chance terá de receber
                perguntas e ofertas exclusivas e turbinar seu ganho de cashback.
              </p>
            </Collapse>
            <Collapse
              className="with-shadow"
              title="Preciso fazer algum cadastro para participar dos sorteios?"
            >
              <p>
                Não. Ao realizar seu cadastro no aplicativo Winn,
                automaticamente ao fazer a leitura dos seus cupons fiscais e o
                mesmo forem validados, você já estará participando dos sorteios.
                Os Sorteios poderão ser realizados no aplicativo pela Winn,
                marcas participantes e/ou Sefaz (Secretaria da Fazenda). A cada
                cupom fiscal que você realizar a leitura no aplicativo Winn e o
                mesmo for você validado, você ganhara número (s) da sorte
                conforme regulamento de cada sorteio cadastrado. Consulte o
                regulamento de cada sorteio no ícone “sorteio” Quanto maior o
                número de cupons validados maior será sua chance de ganhar.
              </p>
            </Collapse>
            <Collapse
              className="with-shadow"
              title="Como funciona os sorteios? É confiável?"
            >
              <p>
                Os sorteios são 100% confiáveis, transparentes e desenvolvidos
                seguindo todas as normas jurídicas. Os sorteios são realizados e
                auditados pela loteria federal e/ou logomania. Qualquer dúvida,
                basta consultar o regulamento disponível em nosso site
                Winn.com.vc e no app, através do ícone sorteio.
              </p>
            </Collapse>
            <Collapse
              className="with-shadow"
              title="Quais os benefícios de usar o Winn?"
            >
              <p>
                Você receberá cashback das marcas participantes e ainda
                concorrerá a prêmios em dinheiro todos os meses ao validar o
                cupom fiscal de suas compras no aplicativo.
              </p>
            </Collapse>
            <Collapse
              className="with-shadow"
              title="Preciso ter algum cartão de crédito/débito específico para usar o Winn?"
            >
              <p>
                Não. O cashback que você vir a ganhar com suas compras estarão
                na sua conta digital WinnPay no próprio aplicativo. Todas as
                transações poderão ser feitas por ele, até mesmo realizar
                transferências.
              </p>
            </Collapse>
            <Collapse className="with-shadow" title="Como recebo meu cashback?">
              <p>
                Ao se cadastrar no app Winn, automaticamente foi aberta a sua
                conta (carteira digital) WinnPay. Todos os cashback caem
                diretamente na carteira digital WinnPay do usuário, onde somente
                o mesmo tem acesso. Não é ponto não é desconto! È dinheiro. Para
                usar onde e como quiser. A Carteira WinnPay disponibiliza
                diversos serviços digitais, tais como: Pagar uma conta, realizar
                recarga de celular, pagar um amigo, doar para instituições
                cadastradas no aplicativo e realizar transferências.
              </p>
            </Collapse>
            <Collapse
              className="with-shadow"
              title="Posso transferir dinheiro para outro usuário?"
            >
              <p>
                Sim, você poderá transferir dinheiro automaticamente para
                qualquer outro usuário do WinnPay através do número do celular
                do destinatário ou por QR Code via WinnPay.
              </p>
            </Collapse>
            <Collapse
              className="with-shadow"
              title="Após realizar leitura dos meus cupons fiscais no aplicativo, eu preciso guarda-los?"
            >
              <p>
                Não, Não! Somos totalmente digitais e nossa proposta é ser o
                mais simples e práticos possível! Nada de ter que guardar
                papelada desnecessária! Após realizar a leitura do seu cupom
                fiscal e o mesmo for aceito (validado) no aplicativo,
                disponibilizamos uma versão digital do seu cupom fiscal,
                disponíveis no histórico do aplicativo.
              </p>
            </Collapse>
            <Collapse
              className="with-shadow"
              title="Eu só posso inserir cupom fiscal com o meu CPF?"
            >
              <p>
                Não. Você pode inserir cupom fiscal sem o CPF, porém, nesse caso
                não terá a possibilidade de ganhar cashback das marcas
                participantes, mas se o cupom for aceito (validado) você recebe
                um número da sorte para participar dos sorteios mensais de 10
                (dez) prêmios de R$ 1.000,00, sendo assim, orientamos que
                informe seu CPF nos cupons fiscais para aumentar e turbinar as
                possibilidades de ganho de cashback.
              </p>
            </Collapse>
            <Collapse
              className="with-shadow"
              title="Não consigo transferir o meu dinheiro da carteira WinnPay para outra conta bancária, o que devo fazer?"
            >
              <p>
                Ao realizar a transferência do seu dinheiro da sua carteira
                digital WinnPay para outros bancos, fique atento (a) as
                seguintes informações: Você precisa ter um valor mínimo de R$
                10,00 + valor para taxa de transferência no valor de 1% sobre o
                valor da transferência com mínimo de R$ 1,00. Verificar se está
                tentando realizar a operação em dia útil dentro do horário
                disponível (10:00hs as 16:50hs) Não se esqueça de verificar
                todos os dados da conta que deseja enviar o dinheiro se estão
                corretos.
              </p>
            </Collapse>
            <Collapse
              className="with-shadow"
              title="Ao tentar transferir o dinheiro da minha conta WinnPay para outro banco, o botão transferir não habilita, o que devo fazer?"
            >
              <p>
                Quando o botão transferir não habilita, significa que ou você
                não possui o saldo mínimo em conta de R$ 10,00 + valor de taxa
                ou você está esquecendo e deixar reservado o valor da taxa. A
                taxa de transferência é 1% sobre o valor da transferência, com
                valor mínimo de R$1,00 ou seja, você deve deixar o valor da taxa
                reservado e não deve inclui-lo no valor da transferência.
                Exemplo: Se houver um saldo total de R$11,00 você vai conseguir
                transferir R$ 10,00 deixando reservado R$ 1,00 correspondente a
                taxa. Valor mínimo para transferência é de R$ 10,00 lembrando
                que R$ 1,00 é de taxa, ou seja, tem que ter um valor mínimo de
                R$ 11,00 para conseguir realizar a transferência.
              </p>
            </Collapse>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
);

export default FAQs;
