import React from 'react';
import { Col, Row, Container } from 'reactstrap';

const video =
  'https://winn-files.s3.sa-east-1.amazonaws.com/site/1616364012638.mp4';

const Header = () => (
  <div>
    <div className="landing__header_video">
      <Container>
        <Row>
          <Col md={12}>
            <video src={video} controls className="landing__video2" />
          </Col>
        </Row>
      </Container>
    </div>
  </div>
);

export default Header;
