import React from 'react';
import { Col, Row, Container } from 'reactstrap';

const FeatureRequest = () => (
  <section className="landing__section">
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="landing__section-title">Cadastre sua empresa</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <center>
            <div>
              <a
                className="landing__btn landing__btn--nav landing__btn--parc"
                target="_blank"
                rel="noopener noreferrer"
                href="https://dash.winn.com.vc"
              >
                Seja um parceiro
              </a>
            </div>
          </center>
        </Col>
      </Row>
    </Container>
  </section>
);

export default FeatureRequest;
