import React from 'react';
import Header from '../../shared/Header';
import Footer from '../../shared/Footer';
import FAQs from './components/FAQs';

const Faq = () => {
  return (
    <div className="landing">
      <Header title="FAQ" />
      <FAQs />
      <Footer />
    </div>
  );
};

export default Faq;
