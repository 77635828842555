import React from 'react';
import { Col, Row, Container } from 'reactstrap';
import raffles from '../../../shared/data/winners';

const img = `${process.env.PUBLIC_URL}/img/landing/people/02.png`;

const Winners = () => (
  <Container>
    <Row style={{ marginBottom: '150px' }}>
      <Col md={12}>
        <h2 className="landing__section-title">
          Quanto mais cupons você escanear, mais chances você tem de ganhar!
        </h2>
      </Col>
      <Col md={12} className="d-flex justify-content-center">
        <p className="landing__section-description text-center">
          O próximo sorteio acontecerá no dia <b>12 de Outubro</b>.
          <br />A divulgação do ganhador será feita até <b>10 dias</b> após o
          sorteio.
        </p>
      </Col>
    </Row>
    <Row className="mt-4">
      <Col
        md={6}
        className="d-flex justify-content-center mt-3"
        style={{ flexWrap: 'wrap' }}
      >
        <p className="winners__title">
          Ganhador do Sorteio de {raffles[0].month.split(' ')[0]}/
          {raffles[0].month.split(' ')[1]}
          {raffles[0].winners.map((winner) => (
            <p className="winners__name" key={winner.name}>
              <b>{winner.name} </b> <br />
              {winner.location}
            </p>
          ))}
        </p>
        {/* <ul style={{ marginTop: '30px', listStyleType: 'none' }}>
          {raffles[0].winners.map((winner) => (
            <li className="winners__name" key={winner.name}>
              <b>{winner.name} </b> <br />
              {winner.location}
            </li>
          ))}
        </ul> */}
      </Col>
      <Col md={6} className="d-flex justify-content-center mt-3">
        <img src={img} alt="" style={{ width: '60%' }} />
      </Col>
    </Row>
  </Container>
);

export default Winners;
