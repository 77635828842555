import React from 'react';
import Header from '../../shared/Header';
import Footer from '../../shared/Footer';
import PdfViewer from './components/Regulamento';

const RegulamentoPage = () => {
  return (
    <div className="landing">
      <Header title="Regulamento" subTitle=" " />
      <PdfViewer />
      <Footer />
    </div>
  );
};

export default RegulamentoPage;
