import React from 'react';
import Header from '../../shared/Header';
import Footer from '../../shared/Footer';
import Winners from './components/Winners';
import Information from './components/Information';

const Sorteio = () => {
  return (
    <div className="landing">
      <Header title="Sorteio" />
      <Winners />
      <Information />
      <Footer />
    </div>
  );
};

export default Sorteio;
