import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MainWrapper from './MainWrapper';
import Home from '../Home/index';
import ContactUs from '../ContactUs/index';
import Sorteio from '../Sorteio/index';
import RegulamentoPage from '../Regulamento/index';
import Faq from '../Faq/index';
import Parceiro from '../Parceiro/index';

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/fale" component={ContactUs} />
        <Route exact path="/sorteio" component={Sorteio} />
        <Route exact path="/faq" component={Faq} />
        <Route exact path="/regulamento" component={RegulamentoPage} />
        <Route exact path="/ganhadores" component={Sorteio} />
        <Route exact path="/parceiro" component={Parceiro} />
        <Route
          exact
          path="/termos-uso"
          component={() => {
            window.location.href = 'https://dash.winn.com.vc/termos-usuario';
            return null;
          }}
        />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
