import React from 'react';
import { Col, Row, Container } from 'reactstrap';

const urlAndroid = 'https://play.google.com/store/apps/details?id=com.winnpayments.winn&hl=pt_BR&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1';
const urlApple = 'https://apps.apple.com/us/app/winn-fidelização-cashback/id1511253117?l=pt&ls=1';
const appleImg = `${process.env.PUBLIC_URL}/img/landing/apple_badge.svg`;
const FeatureRequest = () => (
  <section className="landing__section">
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="landing__section-title">Baixe agora o aplicativo</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="landing__center-btn">
            <a href={urlAndroid}>
              <img
                alt="Disponível no Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/pt-br_badge_web_generic.png"
                className="landing__store"
              />
            </a>
            <a href={urlApple}>
              <img
                alt="Disponível no Google Play"
                src={appleImg}
                className="landing__store2"
              />
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
);

export default FeatureRequest;
