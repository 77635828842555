import React from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Collapse from '../../../shared/components/Collapse';
import raffles from '../../../shared/data/winners';

const PreviousWinners = () => {
  return (
    <Container className="landing__faq">
      <Row className="px-4">
        <Col md={12}>
          <h2 className="landing__section-title">
            Ganhadores dos sorteios anteriores
          </h2>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              {raffles.slice(1).map((raffle) => (
                <Collapse
                  className="with-shadow"
                  title={raffle.month}
                  key={raffle.month}
                >
                  <ul style={{ listStyleType: 'none' }}>
                    {raffle.winners.map((winner) => (
                      <li className="winners__name" key={winner.name}>
                        <b>{winner.name} </b> <br />
                        {winner.location}
                      </li>
                    ))}
                  </ul>
                </Collapse>
              ))}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PreviousWinners;
